<template>
  <div>
    <div class="
        head
        my-1
        d-md-flex
        justify-content-end justify-content-md-between
        align-items-center
      ">
      <b-form class="input-search mb-3" @submit.prevent="handleSearch(search)">
        <b-form-input @input="handleSearch" v-model="search" placeholder="Digite para pesquisar" class="col-3 mb-2" />
        <b-button type="submit" variant="light" class="px-2 d-flex align-items-center absolute icon-action">
          <b-icon icon="search" />
        </b-button>
      </b-form>

      <b-button :to="`/homologation/${homologation_id}/service/add/details`" class="btn-add"
        v-permission="['admin', 'borrower']">
        Adicionar serviço
      </b-button>
    </div>

    <div class="d-flex mb-4 justify-content-start">
      <b-badge href="#" @click="handleGetByStatus(sum.status)" :variant="selectedStatus === sum.status
          ? getStatus(sum.status).variant
          : 'default'
        " class="d-table mr-2" :active="selectedStatus === sum.status" v-for="(sum, index) in summary" :key="index">
        {{ sum.total }} {{ getStatus(sum.status).label }}
      </b-badge>
    </div>
    <empty-list v-if="isEmpty" text="Esta lista está vazia" :size="50">
      <router-link :to="`/homologation/${homologation_id}/service/add/details`" v-permission="['admin', 'borrower']">
        <b-button class="btn-add">
          Cadastre seu primeiro serviço
        </b-button>
      </router-link>
    </empty-list>
    <skeleton :height="is_mobile ? '48px' : '312px'" grid="4" gap="10" :rows="4" v-if="inLoding" />

    <cards-list grid="3" responsive="1" gap="12" v-if="!inLoding && !isEmpty">
      <card-service v-for="(service, index) in services" :key="index" :selectedService="selectedService"
        :service="service" @update="getAllServices()" />
    </cards-list>
    <b-pagination v-if="total > 10" @change="handleChangePage" v-model="current_page" :total-rows="total"
      :per-page="per_page" aria-controls="my-table" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import CardService from "./CardService";

export default {
  components: {
    CardService,
  },
  data() {
    return {
      isEmpty: false,
      per_page: 10,
      current_page: 1,
      total: 1,
      summary: [],
      selectedStatus: null,
      homologation_id: null,
      search: null,
      inLoding: false,
      selectedService: null,
      homologation: {},
      services: [],
    };
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  methods: {
    ...mapActions(["get_all_service_scope", "delete_service_scope"]),
    getStatus(status = 0) {
      let variant;
      let label;
      switch (status) {
        case 0:
          variant = "info";
          label = "Não inicializados";
          break;
        case 1:
          variant = "secondary";
          label = "Em progresso";
          break;
        case 2:
          variant = "success";
          label = "Finalizados";
          break;
      }
      return {
        variant,
        label,
      };
    },

    handleChangePage(current_page) {
      this.current_page = current_page;
      this.getAllServices(this.search, current_page);
    },

    handleGetByStatus(status) {
      if (status === this.selectedStatus) {
        this.selectedStatus = null;
        this.getAllServices();
        return;
      }
      this.selectedStatus = status;
      this.getAllServices();
    },

    getAllServices(search, current_page) {
      this.inLoding = true;
      this.get_all_service_scope({
        search,
        current_page,
        status: this.selectedStatus,
        homologation_id: this.homologation_id,
      }).then((data) => {
        this.summary = data.summary;
        this.current_page = data.current_page;
        this.per_page = data.per_page;
        this.total = data.total;
        this.services = data?.results?.map((item) => ({
          ...item,
          visible: false,
        }));
        this.isEmpty = this.services?.length === 0;
        this.inLoding = false;
        this.selectedService = data?.results[0];
      });
    },

    handleSearch: _.debounce(function (value) {
      this.search = value;
      this.getAllServices(this.search);
    }, 500),
  },
  mounted() {
    this.homologation_id = this.$route.params.homologation_id;
    this.getAllServices();
  },
};
</script>

<style lang="scss">
.services-list {
  grid-template-columns: repeat(4, 1fr) !important;

  @media (max-width: $break-md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card-service {
  .modules {
    filter: grayscale(40%);

    &.disabled {
      cursor: not-allowed !important;
      pointer-events: none;
      user-select: none;
      opacity: 0.4;
      filter: grayscale(100%);
    }

    &:hover {
      filter: grayscale(0);
      box-shadow: 0 0.5rem 1rem rgb(0, 0, 0, 0.15);
    }
  }
}
</style>
